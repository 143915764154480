<template>
  <div style="position: relative; height: 50vh">
    <canvas id="status-chart"></canvas>
  </div>
</template>

<script>
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {
  Chart,
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from 'chart.js';

Chart.register(
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
  ChartDataLabels
);

export default {
  props: {
    chartData: {
      type: Object,
      required: true
    },

    processType: {
      type: Number,
      required: true
    }
  },

  mounted() {
    const keys = Object.keys(this.chartData);
    const labels = [];

    const dataSet = [];
    for (let i = 0; i < keys.length; i++) {
      let label = '';

      if (this.processType === 2) {
        label = this.$t(`dashboard.status.2.${keys[i]}`);
      } else {
        label = this.localizeStatus(this.processType, keys[i]);
      }

      labels.push(label);
      dataSet.push(this.chartData[keys[i]]);
    }

    const data = {
      labels: labels,
      datasets: [
        {
          label: this.$t('reporting.statusReportName', [
            this.$t(`feedbacks.processTypes.${this.processType}`)
          ]),
          data: dataSet,
          backgroundColor: 'rgba(0, 159, 218, 0.5)',
          borderColor: '#009fda',
          borderWidth: 1,
          datalabels: {
            color: 'rgba(0, 0, 0, 0.87)',
            anchor: 'end',
            align: 'top'
          }
        }
      ]
    };

    const config = {
      type: 'bar',
      data: data,
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        },

        maintainAspectRatio: false
      }
    };

    const ctx = document.getElementById('status-chart');
    new Chart(ctx, config);
  }
};
</script>
